<template>
  <div class="container wrapper wrapper_space-top">
    <h1 class="msg" v-if="!skipInvitation">
      Invitations had been
      <br />
      sent successfully.
    </h1>
    <h1 v-else class="msg mx-12 my-12">No invitation sent.</h1>
    <div class="d-flex justify-center btn-holder2">
      <div class="text-center">
        <v-btn class="btn1" @click="$router.push('/job_organizer')">
          Back to Home
        </v-btn>

        <div class="underlineBtn mt-5" @click="$router.push('/job_organizer')">
          See Post
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InviteDone',
    computed: {
      skipInvitation() {
        return this.$route.params?.skipInvitation;
      }
    }
  };
</script>
<style lang="scss">
  .msg {
    font-size: 3rem;
    text-transform: uppercase;
  }
  .btn-holder2 {
    margin-top: 200px;
    margin-bottom: 70px;
  }
  @media (max-width: 599px) {
    .msg {
      font-size: 2rem;
    }
    .btn-holder2 {
      margin-top: 100px;
    }
  }
  .msg-holder {
    margin-top: 100px !important;
  }
</style>
